import { gql } from '@apollo/client';

export default gql`
  query getUnifiedDashboard(
    $byMonth: Boolean
    $byProject: Boolean
    $year: String
  ) {
    offers {
      id
      crewStartDate
      currency
      dailyRate
      goToOffer
      hourlyRate
      jobTitle
      projectName
      projectStartDate
      stateW4
      status
      statusDisplay
      statusColor
      type
      viewAllOffers
      weeklyRate
    }
    paystubs(year: $year) {
      employeeName
      checkSequence
      source
      projectNames
      jobDescriptions
      weekStartDate
      weekStartingDate
      weekEndingDate
      weekEndDate
      payPeriodStart
      payPeriodEnd
      checkPayPeriodOverride
      grossEarnings
      netEarnings
      hoursWorked
      currency
      year
    }
    yearToDate(byMonth: $byMonth, byProject: $byProject, year: $year) {
      employeeName
      grossEarnings
      netEarnings
      employeeTaxes
      hoursWorked
      currency
      year
      month
      monthNum
      projectNumber
      projectName
      source
    }
  }
`;
