import { gql } from '@apollo/client';

export default gql`
  query getReissuePaystubs {
    reissuePaystubs {
      bankName
      batchNumber
      checkDate
      checkNetAmount
      checkNumber
      clientName
      datesWorked
      payrollCompany
      ticketOwner
    }
  }
`;
